import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

export default styled('main')`
  background: #fff;
  flex: 1 1 auto;
  margin-top: 87px;
  padding-bottom: 2rem;
  ${theme('below.lg')} {
    margin-top: 4.5rem;
  }
`;
