import ChannelContext from '@jetshop/core/components/ChannelContext';
import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx } from 'emotion';
import React, { Component } from 'react';
import styled from 'react-emotion';
import { Price } from '../Price';
import AddFavoriteButton from '../FavoriteList/AddFavoriteButton';
import theme from '@jetshop/ui/utils/theme';
import { SaleBadge } from '../ui/SaleBadge';
import { LipscoreProductSmallRating } from '../ProductPage/Lipscore/LipscoreProductSmallRating';

const Wrapper = styled('li')`
  font-size: 1rem;

  .img-wrap {
    [data-flight-image-children] {
      position: absolute !important;
      pointer-events: none;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
    position: relative;
  }

  .product-card-detail {
    background: white;
    padding: 15px 0;
    line-height: 1.35;
    ${({ theme }) => theme.below.lg} {
      padding: 5px 0 0 0;
    }

    h3 {
      font-size: 0.8rem;
      letter-spacing: 0.5px;
      font-weight: normal;
      font-family: ${theme('fonts.body')};
      ${({ theme }) => theme.below.md} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 43vw;
        font-size: 12px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 1em;
    }
  }
`;

const Positioning = styled('div')`
  background: transparent;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

const PriceAndReview = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  .price {
    width: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-right: 10px;
  }
  div {
    ${({ theme }) => theme.below.md} {
      font-size: 12px;
    }
  }
`;

const StyledPrice = styled(Price)`
  font-family: ${theme('fonts.body')};
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;

  ${({ theme }) => theme.below.lg} {
    flex-direction: column;
  }

  ${Price.New} {
    font-weight: bold;
  }
  ${Price.Old} {
    color: black;
  }
  ${Price.Normal} {
    font-weight: bold;
  }
`;

const StyledBadges = styled('div')`
  .badge-bottom-left {
    > div {
      margin-top: 3px;
    }
  }
  .badge-bottom-right {
    > div {
      margin-top: 3px;
    }
  }
  .badge-top-left {
    > div {
      margin-bottom: 3px;
    }
  }
  .badge-top-right {
    > div {
      margin-bottom: 3px;
    }
  }
  .text {
    background: black;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    min-width: 50px;
    padding: 3px 5px;
    font-weight: bold;
    letter-spacing: 1px;
    ${theme('below.lg')} {
      font-size: 8px;
      min-width: 40px;
      padding: 1px 5px;
    }
  }

  .red {
    background: rgb(139 52 52 / 80%);
  }
  .black {
    background: rgb(0 0 0 / 80%);
    color: white;
  }
  .white {
    background: rgb(255 255 255 / 80%);
    color: black;
  }
  .green {
    background: #5d6f23;
    background: rgb(93 111 35 / 80%);
    color: white;
  }
`;

const LipScoreWrapper = styled('span')`
  margin: 0;
  text-align: center;
  text-align: -webkit-center;
  min-height: 14px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

class ProductCard extends Component {
  initialState = {
    image: this.props.product.images && this.props.product.images[0]
  };
  state = this.initialState;

  componentDidMount = () => {
    if (this.isSecondImage()) {
      this.initialState = {
        image: this.props.product.images && this.props.product.images[1]
      };
      this.setState(this.initialState);
    }
  };

  isSecondImage = () => {
    let imageIndex = this.props.index + 1;
    if (
      this.props.product.images &&
      this.props.product.images.length > 1 &&
      this.props.categoryPage
    ) {
      if (imageIndex % 9 === 0 || imageIndex % 9 === 8) {
        return true;
      }
    }
    return false;
  };

  switchToInitialImage = () => {
    this.setState(this.initialState);
  };

  switchToSecondImage = () => {
    if (this.isSecondImage()) {
      this.setState({ image: this.props.product.images[0] });
    } else if (this.props.product && this.props.product.images[1]) {
      this.setState({ image: this.props.product.images[1] });
    }
  };

  render() {
    const {
      product,
      className,
      imageAspect = '2:3',
      imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
      groupedProducts,
      recommendation_id,
      onClickCallback,
      showed,
      ...linkProps
    } = this.props;
    const hasImages = product.images && product.images.length > 0;

    return (
      <Wrapper
        data-testid="product"
        className={cx('product-card', className)}
        data-recommendation-id={
          recommendation_id && recommendation_id[0]?.recommendation_id
        }
        onClick={onClickCallback}
      >
        <ProductLink
          product={product}
          {...linkProps}
          onMouseEnter={this.switchToSecondImage}
          onMouseLeave={this.switchToInitialImage}
        >
          {hasImages && (
            <div className="img-wrap">
              <Positioning>
                <AddFavoriteButton product={product} />
              </Positioning>
              <ChannelContext.Consumer>
                {({ selectedChannel }) => (
                  <>
                    {product.previousPrice && selectedChannel.id === 6 ? (
                      <SaleBadge
                        price={product.price}
                        previousPrice={product.previousPrice}
                      />
                    ) : null}
                  </>
                )}
              </ChannelContext.Consumer>
              <Image
                sizes={this.props.cart ? '260' : imageSizes}
                aspect={imageAspect}
                alt={product.images[0].alt}
                src={this.state.image && this.state.image.url}
                crop={true}
                cover={true}
              >
                <StyledBadges>
                  <Badges badges={product.badges} />
                </StyledBadges>
              </Image>
            </div>
          )}

          <section className="product-card-detail">
            <header>
              <h3>{product.name}</h3>
              <h4 className="sub-name">{product.subName}</h4>
            </header>
            <PriceAndReview>
              <StyledPrice
                price={product.price}
                previousPrice={product.previousPrice}
              />
              {showed && (
                <LipscoreProductSmallRating product={product} onCard />
              )}
            </PriceAndReview>
          </section>
        </ProductLink>
      </Wrapper>
    );
  }
}
export default ProductCard;
