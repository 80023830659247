import { Above } from '@jetshop/ui/Breakpoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import React from 'react';
import styled, { css } from 'react-emotion';
import { Query } from 'react-apollo';
import { theme } from '../Theme';
import productRowQuery from '../Shortcodes/ProductRowQuery.gql';
import { ProductGrid } from '../CategoryPage/ProductGrid';
import { NewProductCard } from '../CategoryPage/NewProductCard';
import { ReactComponent as NextIcon } from '../../svg/NextIcon.svg';
import { ReactComponent as PrevIcon } from '../../svg/PrevIcon.svg';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 1280px;
  padding: 1rem 2rem;
  margin: 1rem auto;
  ${theme.below.lg} {
    padding: 1rem 0;
    &.small-products {
      padding: 10px 0;
      margin: 0;
    }
  }
  &.small-products {
    h2 {
      font-size: 1rem;
    }
  }
  &.mobile-styling {
    padding: 0;
    h2 {
      font-size: 14px;
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 1px;
  }
`;

const ProductWrapper = styled('div')`
  .slick-slide {
    padding: 0 5px;
  }

  button.slick-arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    transition: background 0.3s ease-in-out;
    margin: 0 0.5rem;
    border: 0;
    appearance: none;
    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }
    svg {
      position: absolute;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      top: 50%;
      width: 15px;
      height: 15px;
    }
    &.slick-next {
      right: 10px;
    }
    &.slick-prev {
      left: 10px;
    }
  }

  li {
    list-style-type: none;
  }

  &.mobile-styling {
    padding-bottom: 0;
    padding: 0 10px;
    grid-gap: 10px;
    .product-card {
      min-width: 175px;

      [data-flight-image-children],
      .favorite-icon {
        display: none;
      }
      /* flex: none; */
      a {
        margin-bottom: 0;
      }
    }

    &.small-products {
      .product-card {
        min-width: 40vw;
        .favorite-icon,
        [data-flight-image-children] {
          display: none;
        }
      }
    }

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
  ${({ theme }) => theme.below.lg} {
    padding-bottom: 0;
    padding: 0 10px;
    grid-gap: 10px;
    .product-card {
      min-width: 55vw;
      /* flex: none; */
      a {
        margin-bottom: 0;
      }
    }

    &.small-products {
      .product-card {
        min-width: 40vw;
        .favorite-icon,
        [data-flight-image-children] {
          display: none;
        }
      }
    }

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const DepictProducts = ({
  products,
  title,
  smallProducts,
  mobileStyling,
  productObject,
  onClickCallback
}) => {
  if (!products?.length > 0) return null;

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoPlay: false,
    slidesToShow: mobileStyling ? 1 : smallProducts ? 4 : 4,
    slidesToScroll: 1,
    centerMode: mobileStyling ? true : false,
    nextArrow: (
      <button className="slick-arrow slick-next">
        <NextIcon />
      </button>
    ),
    prevArrow: (
      <button className="slick-arrow slick-prev">
        <PrevIcon />
      </button>
    )
  };
  return (
    <Query
      variables={products && { articleNumbers: products }}
      query={productRowQuery}
      ssr={true}
      errorPolicy={'ignore'}
    >
      {({ loading, error, data }) => {
        if (loading || error) return null;
        if (data && !data.products.length) return null;
        if (!data?.products?.length > 0) return null;

        return (
          <Wrapper
            className={`${smallProducts && 'small-products'} ${mobileStyling &&
              'mobile-styling'}`}
          >
            {title ? <h2>{title}</h2> : null}
            {data?.products.length > 4 ? (
              <Above breakpoint="lg">
                {matches =>
                  matches ? (
                    <ProductWrapper
                      className={smallProducts ? 'small-products' : ''}
                    >
                      <Slider {...sliderSettings}>
                        {data?.products?.map((product, i) => {
                          const variants = product?.variants?.values;
                          const recommendation_id = productObject?.filter(
                            depictProd =>
                              variants?.find(
                                variant =>
                                  variant?.articleNumber ===
                                  depictProd?.articleNumber
                              )
                          );
                          return (
                            <NewProductCard
                              key={i}
                              product={product}
                              groupedProducts={[]}
                              recommendation_id={recommendation_id}
                              onClickCallback={onClickCallback}
                            />
                          );
                        })}
                      </Slider>
                    </ProductWrapper>
                  ) : (
                    <ProductWrapper
                      className={`${smallProducts &&
                        'small-products'} ${mobileStyling && 'mobile-styling'}`}
                    >
                      {data?.products?.map((product, i) => {
                        const variants = product?.variants?.values;
                        const recommendation_id = productObject?.filter(
                          depictProd =>
                            variants?.find(
                              variant =>
                                variant?.articleNumber ===
                                depictProd.articleNumber
                            )
                        );
                        return (
                          <NewProductCard
                            key={i}
                            product={product}
                            groupedProducts={[]}
                            recommendation_id={recommendation_id}
                            onClickCallback={onClickCallback}
                          />
                        );
                      })}
                    </ProductWrapper>
                  )
                }
              </Above>
            ) : (
              <ProductWrapper
                className={`${smallProducts &&
                  'small-products'} ${mobileStyling && 'mobile-styling'}`}
              >
                <ProductGrid
                  products={data?.products}
                  loading={loading}
                  productObject={productObject}
                  onClickCallback={onClickCallback}
                />
              </ProductWrapper>
            )}
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default DepictProducts;
