import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faHeart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Below, Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import theme from '@jetshop/ui/utils/theme';
import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import styled from 'react-emotion';
import posed from 'react-pose';
import { Link } from 'react-router-dom';
import { ReactComponent as Logotype } from '../../../svg/Logo.svg';
import { ReactComponent as LogotypeOutlet } from '../../../svg/LogoOutlet.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import FavoritesButton from '../../FavoriteList/FavoritesButton';
import FavoritesFlyout from '../../FavoriteList/FavoritesFlyout';
import Selector from './ChannelSelector/Selector';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MainNav from './MainNav';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import SearchBar from './Search/SearchBar';
import SearchButton from './Search/SearchButton';
import TopBar from './TopBar';
import FlyoutMenu from './FlyoutMenu';
import HeaderChannelSelector from './ChannelSelector/ChannelSelector';

library.add(faHeart, faBars);

const Container = styled('header')`
  position: fixed;
  width: 100%;
  z-index: 3;
  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  ${theme('below.lg')} {
    align-items: center;
    background: #fff;
    display: flex;
    height: 4.5rem;
    justify-content: center;
    width: 100%;
    display: block;
    box-shadow: 0px -5px 10px #5b5b5b;
    nav.mobile-header-nav {
      position: relative;
      height: 3rem;
      display: block;
    }
  }

  &.outlet {
    background: ${theme('colors.outletRedLight')};
    nav {
      background: ${theme('colors.outletRedLight')};
      > div > ul > li > a {
        color: white;
        &:hover {
          color: white;
        }
      }
    }
    button svg {
      color: white;
    }
    .badge {
      background: white;
      color: ${theme('colors.outletRed')};
    }
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0 10px;
`;
const Column = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;

  &.center {
    flex: none;
  }

  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
`;

const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;

  ${theme('below.lg')} {
    padding: 10px 5px;
  }

  > svg {
    height: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
`;

const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0;
  padding-right: 0.5rem;
`;

const PosedContainer = styled(
  posed('div')({
    enter: {
      marginTop: 0,
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      marginTop: '-30px',
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)``;

const HeaderIcon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  width: 18px;
`;

const PosedMobileContainer = styled(PosedContainer)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 20;
`;

const Home = styled('div')`
  width: 5rem;

  &.outlet {
    width: 7.5rem;
  }
  a {
    display: block;
    max-width: 90px;
    &.outlet {
      max-width: 150px;
      width: 100%;
    }
  }
  svg.regular {
    * {
      fill: ${theme('colors.black')};
    }
  }
  ${theme('below.lg')} {
    height: auto;
  }
`;

const MobileChannelSelector = styled('div')`
  > div {
    top: 73px;
    height: calc(100vh - 73px);
    overflow-y: scroll;
    > div > div {
      padding-top: 0;
    }
  }
`;

function Header(props) {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );
  return (
    <>
      <ChannelBanner />
      <Below breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <Container
                className={selectedChannel.id === 6 ? 'outlet' : 'not-outlet'}
                id="stickyHeader"
              >
                <TopBar />
                <nav className="mobile-header-nav">
                  <PosedMobileContainer key={'mobileMenuPosed'}>
                    <HeaderRow>
                      <Column className="left">
                        <DrawerTrigger preventOverflow={true} id="menu-drawer">
                          {drawer => (
                            <MenuButton
                              onClick={
                                // data-testid="header-cart"
                                drawer.isOpen
                                  ? drawer.hideTarget
                                  : drawer.showTarget
                              }
                            >
                              <IconContainer>
                                <HeaderIcon icon={['fal', 'bars']} />
                              </IconContainer>
                            </MenuButton>
                          )}
                        </DrawerTrigger>
                        <SearchButton
                          searchOpen={searchOpen}
                          setSearchOpen={setSearchOpen}
                          style={{ outline: 'none' }}
                        />
                      </Column>
                      <Column className="center">
                        {selectedChannel.id === 6 ? (
                          <Home className="outlet">
                            <Link to="/" className="outlet">
                              <LogotypeOutlet className="outlet" />
                            </Link>
                          </Home>
                        ) : (
                          <Home>
                            <Link to="/" className="regular">
                              <Logotype className="regular" />
                            </Link>
                          </Home>
                        )}
                      </Column>
                      <Column className="right">
                        {selectedChannel.id !== 6 && (
                          <HeaderChannelSelector mobile={true} />
                        )}
                        <FavoritesButton>
                          <HeaderIcon icon={['fal', 'heart']} />
                        </FavoritesButton>

                        <CartButton />
                      </Column>
                    </HeaderRow>
                  </PosedMobileContainer>
                  <FavoritesFlyout />
                  <CartFlyout />

                  <MobileChannelSelector>
                    <DrawerTarget id="mobile-channel-selector">
                      {drawer => (
                        <Drawer isOpen={drawer.isOpen} right>
                          <Selector
                            channels={channels}
                            hideTarget={drawer.hideTarget}
                            selectedChannel={selectedChannel}
                            updateChannel={updateChannel}
                          />
                        </Drawer>
                      )}
                    </DrawerTarget>
                  </MobileChannelSelector>
                </nav>
                {searchOpen && (
                  <SearchBar
                    setSearchOpen={setSearchOpen}
                    searchOpen={searchOpen}
                  />
                )}
                {/* <MobileMenu data={props.result.data} /> */}
                <FlyoutMenu
                  left={true}
                  data={props.result.data}
                  belowHeader={true}
                />
              </Container>
            </>
          ) : (
            <>
              <Container>
                <MainNav categories={props.result} />
              </Container>
            </>
          )
        }
      </Below>
    </>
  );
}

export default props => (
  <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
    {result => <Header result={result} />}
  </Query>
);
